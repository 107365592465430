<template>
  <section class="new-section">
    <header class="new-title">{{ detail.title }}</header>
    <div class="new-date">发布时间：<span>{{ detail.create_time }}</span></div>
    <div class="new-content" v-html="detail.content"></div>
  </section>
</template>
<script>
export default {
  data: () => ({
    detail: null
  }),
  created () {
    this.$store.commit('updateActiveNav', null)
    this.getNew(this.$route.params)
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
      },
      deep: true
    }
  },
  computed: {
    info () {
      return this.$store.getters.companyInfo
    }
  },
  methods: {
    getNew (param) {
      this.https.get('new', param).then(response => {
        if (response.code === 0) {
          this.detail = response.data.detail
          document.title = this.detail.title + '-玛娅家居'
        }
      })
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/about.css';
</style>
